<template>

  <div id="social" style="position: absolute; bottom: 10%; width: 100%;">

    <div class="copyright_alt" >

      <a href="https://farmgod.io" title="Visit the FarmGod website." target="_blank" style="color:rgb(162 162 162)!important;margin-right: 3px;"><b>FarmGod.io</b></a>

      <span class="color08"> // </span>

      <a href="https://t.me/farmgod_io" :title="$gettext('Chat with FarmGod on Telegram.')" target="_blank" class="social-link-footer in-header last w-inline-block" style="margin: 1px 5px 0 3px;">
        <img src="@/assets/img_core/social/tg.sm.png" width="12" alt="telegram" style="margin-top: -1px;">
      </a>
      <a href="https://youtube.com/@farmgod_io" :title="$gettext('Follow FarmGod on Youtube.')" target="_blank" class="social-link-footer in-header w-inline-block" style="margin: 1px 5px 0 0px;">
        <img src="@/assets/img_core/social/yt.sm.png" width="12" alt="youtube" style="margin-top: -1px;">
      </a>
      <a href="https://twitter.com/farmgod_io" :title="$gettext('Follow FarmGod on Twitter/X.')" target="_blank" class="social-link-footer in-header w-inline-block" style="margin: 1px 5px 0 0px;">
        <img src="@/assets/img_core/social/tw.sm.png" width="13" alt="twitter" style="margin-top: -1px;">
      </a>
      <img src="@/assets/img_core/social/mail.sm.png" width="12" title="Subscribe to the Newsletter." @click="$refs.NewsletterSub.$refs.nlSub.openModal();" style="margin: 0px 5px 0 0px; cursor: pointer;">

      <br v-if="isMobile()">

      <span v-if="!isMobile()" class="color08" style="margin-right:2px;">// </span>
      <span v-if="!isMobile()">Beta</span>

    </div>

    <NewsletterSub ref="NewsletterSub" />

  </div>

</template>

<script>
import NewsletterSub from '@/components/widgets/NewsletterSub.vue'
import { PRODUCT_MODE } from '@/common/config'

export default {
  name: 'FooterLoggedOut',
  components: { NewsletterSub },
  data () {
    return {
      product_mode: PRODUCT_MODE
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
