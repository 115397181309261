<template>

  <div :class="'fI mobile_' + isMobile()">

    <div id="loginWidget">
      <div class="pulsate loginWidgetText">
        <div>~ <translate>2FA SETUP</translate> ~</div>
      </div>
    </div>

    <!--LoginWidgets /-->

    <div style="overflow: hidden;">

      <FarmgodHeader>
        <div class='row' style="top: 10%; position: sticky;">
          <div class='col-sm-12'>
            <h1 id="logo_main" class="teaserLogoMain">
              <Logo v-bind:link="'none'" v-bind:title="'Powered by FarmGod.io'"/>
            </h1>
          </div>
        </div>
      </FarmgodHeader>

      <div style="overflow: hidden;">

        <FormulateForm
          v-model='values'
          :errors='errors'
          #default="{ hasErrors }"
          @submit='completeOtpSetup()'
        >
          <div id="OtpMethodForm">
            <div id='external-error' v-if='errors.root' class='landingPageInput item-errors alert alert-danger'>
              <translate>Otp setup email error:</translate> {{ errors.root }}
            </div>
            <div class="logo_spacer_top"></div>
            <div class="loginFormWrapper form-group" style="margin: auto;">
              <span v-translate>Please <b>enter the OTP code</b> sent to your email to finish the setup.</span><br/><br/>
                <FormulateInput
                  class="landingPageInput fI-slow otpInput"
                  name='final_otp'
                  validation='bail|number|min:6,length'
                  v-on:input='inputWatcher'
                  type="text"
                  autocomplete="off"
                  maxlength="6"
                  placeholder='. . . . . .'
                  style="margin-bottom: 5px;"
                  :validation-messages="{
                    required: 'Otp is required',
                    number: 'Otp must be a number',
                    min: 'Otp must be 6 digits long.'
                  }"
                />
            </div>
            <div class="logo_spacer_bottom"></div>
            <div class="form-group">
              <button v-if='hasErrors' class='landingPageButton btn btn-primary trans pulsate fI' style="opacity: 0.3; cursor: not-allowed;" name='confirm_otp' :value="$gettext('Confirm')"><translate>CONFIRM ACCOUNT</translate></button>
              <button v-else class='landingPageButton btn btn-success trans pulsate fI' name='confirm_otp' :value="$gettext('Confirm')"><translate>CONFIRM ACCOUNT</translate></button>
              <br/>
              <span class='loginSubBtn w-50 mt-2 btn btn-outline-secondary btn-small fI' @click='requestEmailOTP'>
                <translate>Resend the email</translate>
              </span>
            </div>
          </div>
        </FormulateForm>

      </div>

    </div>

    <FarmgodFooter>
      <LoggedOutFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { OTP__COMPLETE_SETUP, OTP__REQUEST_SETUP } from '@/store/actions.type'
import FarmgodHeader from '@/components/layout/TheHeader'
import Logo from '@/components/layout/Logo'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedOutFooter from '@/components/layout/Footers/LoggedOut'

export default {
  data () {
    return {
      values: {
        final_otp: null
      },
      errors: {}
    }
  },
  components: { FarmgodHeader, Logo, FarmgodFooter, LoggedOutFooter },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    inputWatcher () {
      if (this.values.final_otp && this.values.final_otp.length === 6) {
        this.completeOtpSetup()
      }
    },
    requestEmailOTP () {
      this.email_requested = true
      this.$store.dispatch(OTP__REQUEST_SETUP, 'email')
    },
    completeOtpSetup () {
      this.$store.dispatch(OTP__COMPLETE_SETUP, this.values.final_otp).then((response) => {
        this.$router.push({ name: 'otp__email', query: { verified_otp: this.values.final_otp } })
      }).catch(err => this.handleErrors(err))
    },
    handleErrors (err) {
      const status = parseInt(err.response.status)
      const otpStatus = err.response.headers['x-2fa-status']

      if (status === 400) {
        if (otpStatus === '2FA_DISABLED_GLOBALLY') {
          this.errors = { root: 'Two factor authentication is disabled' }
        } else if (otpStatus === '2FA_GENERAL_ERROR') {
          this.errors = { root: 'Otp mode is invalid' }
        } else if (otpStatus === '2FA_INVALID_OTP') {
          this.errors = { final_otp: ['Otp is invalid'] }
        } else {
          this.errors = { root: err.response.data.detail }
        }
      } else if (status === 403) {
        if (otpStatus === '2FA_EXPIRED_TOKEN') {
          this.errors = { root: 'Failed to validate 2FA temporary token, either expired or or of invalid type (jti)' }
        } else if (otpStatus === '2FA_INVALID_STATE') {
          this.errors = { root: '2FA mismatch between login/2FA calls' }
        } else if (otpStatus === '2FA_SETUP_DONE') {
          this.errors = { root: '2FA setup already done' }
        } else if (otpStatus === '2FA_SETUP_FAILED') {
          this.errors = { root: '2FA setup failed on invalid OTP' }
        } else if (otpStatus === '2FA_SETUP_NOT_INITIALIZED') {
          this.errors = { root: '2FA setup not initialized' }
        } else {
          this.errors = { root: err.response.data.detail }
        }
      } else if (status === 404) {
        this.errors = { root: 'User not found' }
      } else if (status === 422) {
        const errorsObject = { final_otp: [] }
        err.response.data.detail
          .map((err) => {
            errorsObject[err.loc[1]].push(err.msg)
          })
        this.errors = errorsObject
      }
    }
  },
  created () {
    this.requestEmailOTP()
  }
}
</script>
